//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    padding-bottom: 60px;
    overflow-x: hidden;
}
.dropdown-toggle::after {
    display: none;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-width: 32px;
    height: 21px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
    width: 100%;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 17px;
    height: 17px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}
.react-switch-checkbox:checked + .react-switch-label {
    background: $green;
}
.switch-checkbox {
    display: flex;
}
.switch-checkbox span {
}
.switch-checkbox span:first-child {
    text-transform: uppercase;
    color: black;
    font-size: 11px;
    font-weight: 600;
}
.switch-checkbox-text {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin-right: auto;
}
.form-group label {
    color: black;
    font-size: 11px;
}
