//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
    min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Input
input {
    &:focus {
        outline: none;
    }
}
.presentie__form {
    label {
        font-size: 21px;
        font-weight: 600;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        color: #a2acb5;
        //text-transform: uppercase;
    }
    input::placeholder,
    textarea::placeholder {
        color: #a2acb5;
    }
    textarea,
    input {
        background: #ffffff;
        border: 0.7px solid #d4dbe1;
        box-sizing: border-box;
        border-radius: 6px;
    }
    textarea {
        resize: none;
    }
    .get-pro-card {
        max-width: inherit;
        position: unset;
        transform: inherit;
        top: unset;
        left: unset;
        border: unset;
    }

    .gray-text {
        color: #a2acb5;
    }
}

.react-tagsinput {
    background: #ffffff;
    border: 0.7px solid #d4dbe1;
    box-sizing: border-box;
    border-radius: 6px;
    display: block;
    width: 100%;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b4b5a;
    min-height: 42px;
    display: flex;

    span {
        display: flex;
        flex-wrap: wrap;
    }
    input {
        border: none;
        border-radius: none;
        margin: 0;
        padding: 0;
    }
}
.react-tagsinput-tag,
._7ahQImyV4dj0EpcNOjnwA {
    margin: 0;
    margin: 5px 10px;
    background: rgba(0, 122, 255, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: #007aff;
    padding: 4px 12px;
    font-weight: 600;
    position: relative;
}
.react-tagsinput-input {
    width: inherit;
}
.react-tagsinput-remove {
    background: url(../../../images/close.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
}
.react-tagsinput-remove::before {
    content: '' !important;
}
.modal-content .react-tel-input .form-control {
    width: 100%;
}
.autocomplete-dropdown-container div {
    background: white !important;
    padding: 10px;
    border-bottom: 0.7px solid #d4dbe1;
}
.autocomplete-dropdown-container div:hover {
    background: #d4dbe1 !important;
}
.form-group small {
    color: $main-green-darker;
}
.form-group .red {
    color: $main-green-darker;
}
.presentie__form .custom-control-label {
    line-height: inherit;
    text-transform: inherit;
    font-size: 15px;
    cursor: pointer;
    font-weight: 400;
}

.custom-control-label {
    display: flex;
    flex-direction: column;
    color: black;

    span:last-child {
        font-size: 12px;
        color: #909090;
        text-transform: none;
    }
}

.pac-container {
    z-index: 9999;
}
.multiselect-container {
    .icon_cancel {
        position: absolute;
        right: -8px;
        display: flex;
    }
    .chip {
        margin: 5px 10px;
        background: rgba(0, 122, 255, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        color: #007aff;
        padding: 4px 12px;
        font-weight: 600;
        position: relative;
    }
    .icon_cancel:before {
        content: '';
        background: url(../../../images/close.svg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
    }
    .search-wrapper input {
        margin: 0;
    }
    .optionListContainer {
        border-radius: 0;
        .optionContainer {
            border-radius: none;

            li {
                padding: 5px 10px;
                color: #808080;
                &.highlight {
                    background-color: white;
                    color: #808080;
                }
                &:hover {
                    background: #fc047c;
                    color: white;
                }
            }
        }
    }
}

.custom-presentie {
    th {
        white-space: nowrap;
    }
}

.search-label {
    width: 100%;
    max-width: 400px;

    input {
        height: unset;
        border-radius: 2px;
        padding-left: 40px;
        background: url(../../../icons/search.svg);
        background-repeat: no-repeat;
        background-position: left center;
        background-position-x: 10px;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff !important;
    background-color: #007bff !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #007bff !important;
}

.custom-control-label::before {
    width: 1.5rem;
    height: 1.5rem;
    top: 0.5rem;
}

.custom-control-label::after {
    top: 0.57rem;
    width: 1.5rem;
    height: 1.25rem;
}
.custom-control-label {
    padding-left: 12px;
}

.rich-editor-wrapper a {
    color: black;
}
.form-group .get-pro-card {
    top: unset;
    left: unset;
    transform: unset;
    position: unset;
}
