//
// modal.scss
//

// Title text within header
.modal-title {
    margin-top: 0;
}

// Full modal
.modal-full {
    width: 92%;
    max-width: none;
}

// Custom animation modal
.modal-demo {
    background-color: $white;
    width: 600px !important;
    border-radius: 4px;
    display: none;
    position: relative;
    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        color: $light;
    }
}

@media (max-width: 768px) {
    .modal-demo {
        width: 96% !important;
    }
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: $dark;
    color: $white;
    text-align: left;
    margin: 0;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-wrapper {
    text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
    .close {
        top: 20px;
        z-index: 9999;
    }
}
.modal-body {
    padding: 40px;
    .modal-header {
        padding: 0px;
        padding-bottom: 30px;
        border: none;
        .modal-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

            .close {
                padding: 0;
                margin: 0;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
.modal-content {
    border: none;
    border-radius: 6px;
}
