//
// extra-pages.scss
//

// pricing

.card-pricing {
    .card-pricing-features {
        li {
            padding: 10px;
            &:before {
                color: $primary;
            }
        }
    }
}

.benefit-item {
    display: flex;
    .benefit-icon {
        width: 100%;
        max-width: 40px;
        margin-right: 20px;
        img {
            width: 40px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .benefit-item {
        flex-direction: column;
    }
}

.pro-popup h4 {
    color: black;
}
