/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

@import 'bootstrap';
@import 'icons';
@import 'app';

@media (max-width: 1300px) {
    .hide-on-small-screens {
        display: none;
    }
}
@media (max-width: 767px) {
    .hide-on-phones {
        display: none;
    }
}

@media (min-width: 1000px) {
    .hide-on-small-screens-flex {
        display: flex;
    }
}

@media (max-width: 1000px) {
    .hide-on-small-screens-flex {
        display: none;
    }
}
