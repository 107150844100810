//
// buttons.scss
//

.btn {
    color: $white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 6px;

    &:focus {
        box-shadow: none;
    }

    .icon {
        svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.1rem;
            margin-top: -2px;
        }
    }
}

.mw-300 {
    max-width: 300px;
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
//
.btn-light,
.btn-white {
    color: $gray-900;
}

.btn-white {
    border-color: $gray-300;

    @include hover-focus {
        background-color: $gray-300;
        border-color: $gray-400;
    }
}
//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
}

//
// Button Extra Small Size
//

.btn-xs {
    padding: 0.2rem 0.6rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
}

// Soft variants
@mixin btn-variant-soft($bg, $color) {
    background-color: rgba($bg, $btn-soft-bg-opacity);
    color: $color;
    border-color: rgba($color, $btn-soft-bg-opacity);

    &:focus {
        box-shadow: none;
    }

    @include hover-focus {
        background-color: fade-in(rgba($bg, $btn-soft-bg-opacity), 0.05);
        color: $color;
    }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include btn-variant-soft($value, $value);
    }
}

//
// Alternate buttons
//
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
        &:focus {
            box-shadow: none;
        }
    }
}

.btn-clear {
    border: 1px solid #e1e1e1;
    background: none;
    padding: 5px 25px;
    color: #9e9e9e;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;

    &.export {
        background: url(../../../icons/export.svg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 10px;
        padding-left: 35px;
    }

    &.export:hover {
        background: url(../../../icons/export-green.svg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 10px;
        padding-left: 35px;
    }

    &:hover,
    &:focus,
    &:active {
        color: $main-green-darker;
        border: 1px solid $main-green-darker;
        box-shadow: none !important;
    }

    &.pro {
    }
}
//
// Redesign onboarding button
//
.btn-big {
    padding: 15px 125px;
}

.btn-form {
    margin: 4px;
    padding: 8px 40px;
    background: #ffffff;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    &-active {
        background: rgba(0, 122, 255, 0.1);
        border: 1px solid #007aff;
        color: #007aff;
    }
}

.btn-grey {
    color: white;
    background: #dadada;

    &:hover {
        color: white;
    }
}

.btn-file {
    padding: 0.5rem 1rem;
    color: black;
    background: #e1e1e1;
    font-size: 13px;
}
