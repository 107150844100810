//
// tables.scss
//

//Table centered (Custom)
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    th {
        font-weight: $font-weight-bold;
    }
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

.custom-presentie {
    th {
        color: #a2acb5;
        padding: 0.4rem 0.75rem;
    }
    td {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #000;
    }
    tbody tr {
        &:hover {
            background: rgba(0, 122, 255, 0.05);

            .settings-icon {
                display: flex;
            }
        }
    }
    span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
    }
    @media screen and (max-width: 1250px) {
        .table {
            display: block;
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
        }
    }
}
.entries-select {
    display: inline-block;
    border: 0.8px solid #a2acb5;
    color: #595959;
    font-size: 14px;
    width: fit-content;
    padding: 3px 10px;
    height: inherit;
    margin: 0 10px;
}
.settings-icon {
    display: none;
}
.settings-icon a {
    display: block;
    margin: 0 5px;
    cursor: pointer;
}
.settings-icon img {
    width: 12px;
}
.switch-button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    color: #bababa;
    background: none;
    border: none;
    padding: 0 25px;
    padding-bottom: 15px;
    position: relative;

    &.active {
        color: black;

        &::after {
            content: '';
            width: 40px;
            height: 3px;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            background-color: $main-green-darker;
        }
    }
}
img {
    max-width: 100%;
}

@media screen and (max-width: 1500px) {
    .direction-xl {
        flex-direction: column;
        h5 {
            margin-top: 10px !important;
        }
    }
}
