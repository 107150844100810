//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination {
    flex-wrap: wrap;
    .page-item {
        .page-link {
            border-radius: 30px !important;
            margin: 0 3px;
            border: none;
            background-color: white;
            color: black;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;

            &:focus {
                box-shadow: none;
            }
        }
        &:hover,
        &.active {
            .page-link {
                background-color: #007aff;
                color: white;
                outline: none;
            }
        }
    }
}
