//
// nav.scss
//

// Bootstrap override - turn nav tab with broder

.nav-tabs {
    border-bottom: 2px solid rgba($gray-600, 0.2);

    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border-color: transparent !important;
            padding: 0.625rem 1.25rem;
        }
        a.active {
            border-bottom: 2px solid $primary !important;
        }
    }
}

// pills
.nav-tabs,
.nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $font-weight-bold;
        }
    }
}
.profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    margin-right: 30px;
    display: block;
    z-index: 2;
    float: left;

    &-sm {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        overflow: hidden;
        position: relative;
        margin-right: 10px;
        display: block;
        z-index: 2;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

.nav-pills {
    background: $body-bg;
    border-radius: $border-radius;
    padding: 6px 2px;

    .nav-link {
        background-color: transparent;
        color: $body-color;
    }

    .nav-link.active,
    .show > .nav-link {
        color: $body-color;
        background-color: $card-bg;
        box-shadow: $box-shadow-sm;
    }
}

//Navtab custom
.navtab-bg {
    li {
        > a {
            background-color: $gray-300;
            margin: 0 5px;
        }
    }
}

.tab-content {
    padding: 20px 0 0 0;
}
.sidebar-content {
    background: white;
}
