.mh-event {
    min-height: 900px;
}
.event-detail-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
    max-width: 450px;
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin-top: 1rem;
    .event-detail-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .event-detail-info-image {
            font-size: 14px;
            font-weight: 500;
            color: #9e9e9e;
        }
        .event-detail-info-text,
        .event-detail-info-text a {
            font-size: 14px;
            font-style: normal;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;
            font-weight: 500;
        }
    }
}

.mh-event tr {
    cursor: pointer;
}
.pro-button {
    position: relative;
    &:after {
        content: url(../../../icons/stars.svg);
        position: absolute;
        right: 5px;
        top: 0;
    }
}

.light-grey-text {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 500;
}
.cursor-pointer {
    cursor: pointer;
}
.description {
    font-size: 14px;
    font-style: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    font-weight: 500;
}
.event-detail-nav {
    margin-top: 1rem;
    border-bottom: 1px solid #d6d7d8;
    padding: 0 1rem;
    display: flex;

    button {
        background: none;
        border: none;
        color: #000;
        font-weight: 600;
        padding: 0.75rem 0;
        margin-right: 1.5rem;
        font-size: 16px;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            background: none;
            border-radius: 50px;
            height: 3px;
            position: absolute;
            bottom: -1px;
            left: 0;
        }

        &.active::after,
        &:hover::after {
            background: $main-green-darker;
        }
    }
}

.event-detail {
    table {
        th {
            background: #f9f9f9;
            text-transform: uppercase;
            color: #a2acb5;
            font-size: 13px;
            padding: 0.3rem 0.75rem;
            font-weight: 500;
        }
    }
}

.promo-heading {
    display: flex;
    align-items: center;

    img {
        margin-right: 1rem;
    }
    .h4 {
        color: black;
    }
}
.deletePromoContainer {
    max-width: 400px;
    .deletePromo {
        .modal-header {
            display: flex;
            justify-content: center;
            position: relative;
            text-align: center;

            .modal-title {
                justify-content: center;
            }

            .close {
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        p {
            text-align: center;
            max-width: 250px;
            margin: auto;
        }
        .bottom-wrap {
            display: flex;
            margin-top: 1rem;
            button {
                width: 100%;
                margin: 0.5rem;
            }
        }
    }
}
