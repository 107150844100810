.createEvent {
    .box {
        display: inline-block;
        padding: 10px;
        box-sizing: border-box;
    }
    .img-preview {
        overflow: hidden;
    }
    label {
        text-transform: uppercase;
    }

    .c-progress {
        padding-top: 4rem;

        &-row {
            display: flex;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
        }

        &-card {
            background: white;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 2px solid #fff;
            position: relative;
            flex-direction: column;
            padding: 3.5rem 0;
            margin: 10px;
            cursor: pointer;
            width: 100%;

            &.active,
            &:hover {
                border-color: $main-green-darker;
            }
            &.active::after {
                content: url(./../../../icons/fajfka.svg);
                position: absolute;
                right: -10px;
                top: -10px;
            }
        }
        &-button {
            margin: 10px;
            text-align: center;
            margin-top: 4rem;
        }
    }

    .date-row {
        display: flex;
        .w-30 {
            width: 30%;
        }
        .w-70 {
            width: 65%;
            margin-right: 5%;
        }
    }

    .c-progressBar {
        width: 100%;
        max-width: 200px;
        height: 4px;
        border-radius: 50px;
        background: #f6f6f6;
        margin: 10px;
        position: relative;
        margin: auto;
        transition: 1s all;

        &::after {
            content: '';
            background: $main-green-darker;
            height: 100%;
            width: var(--progress-width);
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50px;
            transition: 1s all;
        }
    }

    .c-image {
        padding-top: 3rem;
        text-align: center;

        h3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
            color: black;
        }
        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #9e9e9e;
        }

        &-container {
            border: 1px solid #e4e4e7;
            filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.04));
            border-radius: 10px;
            min-height: 220px;
            padding: 1px;
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
    .complete {
        padding-top: 4rem;
        text-align: center;
        max-width: 310px;
        margin: auto;
        h2 {
            padding-top: 3rem;
            font-size: 22px;
            max-width: 210px;
            margin: auto;
        }

        .copy-div {
            background: #f2f2f2;
            cursor: pointer;
            border-radius: 4px;
            width: 100%;
            padding: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .advance {
        padding-top: 3rem;
    }
}
