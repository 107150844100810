//
// text styles for all pages
//

.page-heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.gray-text {
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.8125rem; // 13 px
    color: #bababa;
    letter-spacing: 1px;
}

.black-text {
    font-weight: 500;
    font-size: 0.8125rem; // 13 px
    color: black;
}

.money-text {
    font-weight: 600;
    font-size: 1rem;
    color: black;
}

.pink-text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #fc047c;
}

.blue-text {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #007aff;
}

.border-gray {
    border: 1px solid #e3e7eb;
}
